import { useMutation } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import { useNavigate } from 'react-router-dom';

interface MutationProps {
  email: string;
}

const useSendResetLink = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  return useMutation({
    mutationFn: async ({ email }: MutationProps) => {
      await Backend.request('post', `/account/forgot/${projectId}`, {}, { email });
    },
    onSuccess: (_, data) => {
      navigate(`/${projectId}/sentForgot?email=${data.email}`, {
        replace: true,
      });
    },
  });
};

export default useSendResetLink;
