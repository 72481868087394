/* eslint-disable no-useless-escape */
import React from 'react';
import { useForm } from 'react-hook-form';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormLogo from 'components/formLogo/FormLogo';
import FormContainer from '/components/formContainer/FormContainer';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import useSendResetLink from '../../../queries/user/useSendResetLink';
import TextComponent from '/components/form/Text';

interface FieldValues {
  email: string;
  confirmEmail: string;
}

const LoginForgot: React.FC = () => {
  const navigate = useNavigate();
  const sendResetLink = useSendResetLink();
  const { handleSubmit, getValues, control } = useForm<FieldValues>({ mode: 'all' });

  const onSubmit = ({ email }: FieldValues) => {
    sendResetLink.mutate({ email });
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />

          <Typography variant="h6" style={{ marginTop: '16px' }}>
            Reset your password
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextComponent
              name="email"
              control={control}
              label="E-mail"
              rules={{
                required: { value: true, message: 'Enter e-mail' },
                pattern: {
                  // taken from https://emailregex.com/
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                  message: 'Invalid E-mail address',
                },
              }}
            />

            <TextComponent
              name="confirmEmail"
              control={control}
              label="Confirm E-mail"
              rules={{
                required: { value: true, message: 'Confirm e-mail' },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                  message: 'Invalid E-mail address',
                },
                validate: {
                  emailsMatch: (v: string) => (v === getValues('email') ? true : 'E-mails do not match'),
                },
              }}
            />

            <div style={{ margin: '16px 0' }}>
              <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
                Back
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                style={{ float: 'right' }}
                loading={sendResetLink.isPending}
              >
                Reset
              </LoadingButton>
            </div>
          </form>
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default LoginForgot;
